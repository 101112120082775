.chat {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0px 5px;
  margin-top: 10px;
}
.bottom-input {
  position: absolute;
  bottom: 15px;
  width: 100%;
}
.input-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.messages {
  overflow-y: auto;
  width: 100%;
}
.text-area {
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  border-radius: 10px;
  font-size: 14px;
  min-width: 50px;
}

/* .floating-box{
  padding:2px 15px;
  color:white;
  background-color: #003766;
  border-radius:15px;
  width:100px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%,0);
  cursor: pointer;
  opacity: 0.9;
  font-size: 14px;
  font-weight: bold;
} */

.debug-info {
  position: fixed;
  left: 10px;
  top: 10px;
  background-color: #003766;
  color: white;
  padding: 0px 15px;
  border-radius: 10px;
  font-size: 14px;
}

@media (max-width: 500px) {
  .debug-info {
    position: fixed;
    left: 10px;
    top: 5px;
    width: 330px;
  }
  .debug-info p {
    margin: 0;
  }
}
.chat img:not([class]) {
  max-width: 180px;
  height: auto;
}
