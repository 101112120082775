.user{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width:100%;
}
.user-logo{
  height: 32px;
  width: 32px;
}
.user-text{
  background-color: #003766;
  color: #ffffff;
}