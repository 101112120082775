.bot{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width:100%;
}
.bot-logo{
  height: 32px;
  width: 32px;
}
.bot-text{
  background-color: #ececec;
  color: #404040;
}
.cursor {
  display: inline-block;
  width: 8px;
  height: 14px;
  margin-left: 2px;
  background-color: black;
  animation: blink 1s infinite;
}
.thumbs-down-icon:hover{
  cursor: pointer;
  color: #ff0000 !important;
  opacity: 0.7;
}
.div-feedback{
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #ececec;
  margin-left: 40px;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
}
.submit-feedback{
  width: fit-content;
  align-self: center;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
