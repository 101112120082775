body {
  margin: 0;
  padding: 0;
}
.app-maximize {
  align-items: center;
  display: flex;
  justify-content: center;
}
.app {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.chat-icon {
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.header {
  display: flex;
  background-color: #003766;
  color: white;
  height: 55px;
  align-items: center;
}
.header-logo {
  height: 33px;
  width: 33px;
  border: 1px solid white;
  border-radius: 50%;
  margin-left: 10px;
}
.chat-container {
  border: 1px solid #003766;
  margin: 10px;
  position: relative;
}
